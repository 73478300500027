<!--
About Page
Created at 09/04/2021 11:45
Author: Khaliq ALI
!-->
<template>
<div class="about-page w-full h-screen flex justify-center items-center">
  <div class="flex flex-col justify-center">
    <img src="../../assets/images/png/img_logo.png" class="h-64">
    <div class="text-2xl text-center mt-10"> {{ $t('title') }} </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'index'
}
</script>

<style lang="scss" scoped>
</style>
